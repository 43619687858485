import { postLoginApi, getUserInfoApi, getLogoutApi } from 'src/api/user'
import {
  getToken,
  setToken,
  removeToken,
  setIsLogout
} from 'src/util/js/headerToken'
import router from 'src/router'
import { Message } from 'element-ui'
import store from 'src/store'

const state = {
  token: getToken(),
  name: '',
  account: '',
  rolesInfo: [],
  isLogin: false,
  uploadPasswordDialog:false
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    // 用户名字
    state.name = name
  },
  SET_ACCOUNT: (state, account) => {
    // 账号
    state.account = account
  },
  SET_ROLESINFO: (state, rolesInfo) => {
    // 角色信息
    state.rolesInfo = rolesInfo
  },
  SET_IS_LOGIN: (state, loginState) => {
    state.isLogin = loginState
  },
  REMOVE_TOKEN: state => {
    state.token = ''
  },
  SET_IS_LOGOUT: state => {
    state.isLogin = false
  },
  SET_UPDPWDDIALOG:(state,bool) =>{
    state.uploadPasswordDialog = bool
  }
}

const actions = {
  // user login
  getlogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      postLoginApi(userInfo)
        .then(response => {
          const { code, result } = response
          if (code == 200) {
            commit('SET_TOKEN', result.token)
            commit('SET_NAME', result.userName)
            setToken(result.token)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfoApi()
        .then(response => {
          const { result } = response
          // if (!data) {
          //   reject('Verification failed, please Login again.')
          // }
          const { username, name, rolesInfo } = result
          // roles must be a non-empty array
          // if (!rolesInfo || rolesInfo.length <= 0) {
          //   reject('getInfo: roles must be a non-null array!')
          // }
          commit('SET_NAME', name)
          commit('SET_ACCOUNT', username)
          commit('SET_ROLESINFO', rolesInfo)
          localStorage.setItem('USER_INFO', JSON.stringify(rolesInfo))
          localStorage.setItem('USER_ALL_INFO', JSON.stringify(result))
          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      getLogoutApi(state.token)
        .then(response => {
          const { code } = response
          if (code === 200 || code === 0) {
            Message({ type: 'success', message: '退出登录' })
            store.dispatch('menu/changeMenuList', [])
            store.dispatch('menu/changeMenuAllOriginal', [])
            commit('REMOVE_TOKEN')
            commit('SET_ROLESINFO')
            commit('SET_IS_LOGOUT')
            commit('SET_NAME', '')
            setIsLogout()
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login')
          }
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
