<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
    if (!localStorage.getItem('DICTIONARY')) {
      this.$store.dispatch('dictionary/getDictionaryAll')
    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
