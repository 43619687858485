import axios from 'axios'
import qs from 'qs'
import { signActionTime } from 'util/js/headerToken'
class ApiServer {
  constructor() {}
  get(url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          ...options,
          params,
          headers: signActionTime('get', params)
        })
        .then(response => {
          resolve(response ? response.data : {});
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  post(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, { headers: signActionTime('post', params) })
        .then(response => {
          resolve(response ? response.data : {});
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  postByFormData(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params ? qs.stringify(params) : {}, {
          headers: signActionTime('postform', params)
        })
        .then(response => {
          resolve(response ? response.data : {});
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  put(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, params, {
          headers: signActionTime('put', params)
        })
        .then(response => {
          resolve(response ? response.data : {});
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  del(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          url,
          { params: params },
          {
            headers: signActionTime('postform', params)
          }
        )
        .then(response => {
          resolve(response ? response.data : {});
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  downfileStream(url, params = {}) {
    return new Promise(resolve => {
      axios
        .post(url, params, {
          headers: signActionTime('POST', params),
          responseType: 'arraybuffer'
        })
        .then(response => {
          resolve(response)
        })
    })
  }
  upLoadFileStream(url, params) {
  return new Promise((resolve, reject) => {
    axios.request({
      url: url,
      method: "post",
      data: params,
      headers: signActionTime('POSTFILEUPLOAD')
    }).then(response => {
      resolve(response)
    })
  })
}
}

export default new ApiServer()
