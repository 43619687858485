import router from 'src/router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from 'src/util/js/headerToken' // get token
import store from 'src/store'
import { constantRoutes } from '@/router'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

//白名单路径列表
// let constantList = constantRoutes.map((item) => {
//   if (item.children) {
//     let pathList = item.children.map((cItem) => {
//       return cItem.path
//     })
//     return pathList
//   } else {
//     return item.path
//   }
// })
// const whiteList = constantList.flat() // 游客白名单
const whiteList = ['/login', '/404','/forget','/edit','/transition','/clientRegisterMobile','/clientRegisterPC','/submitOK','/data','/unsubscribe','/explain','/untreated'] // whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  const hasToken = getToken()

  window.document.title = to.meta.title || '运营管理'
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 检查动态路由是否加载完毕
      // const menuAllOriginal = store.state.menu.menuAllOriginal
      const menuTreeList = store.state.menu.menuTreeList
      if (menuTreeList.length <= 0) {
        await store.dispatch('menu/setMenuList')
        next({ ...to, replace: true })
      }
      next()
    }
    NProgress.done()
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})
router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
