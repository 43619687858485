import router, { constantRoutes, staticRoutesArr } from 'src/router'
import Layout from 'views/layout'
import { getUserMenu } from 'api/menu'
// import store from 'src/store'

const state = {
  // 菜单路由
  menuAllOriginal: [],
  menuTreeList: []
}

const mutations = {
  SET_ROUTER_MENULIST(state, list) {
    let layout = [
      {
        path: '/',
        component: Layout,
        name: '首页',
        redirect: '/home',
        meta: {
          title: '首页',
          icon: ''
        },
        children: list
      }
    ]
    // 路由合并
    let arr = constantRoutes.concat(layout)
    router.options.routes = arr
    router.options.routes.forEach(item => {
      router.addRoute(item)
    })
    // if (state.menuAllOriginal.length === 0) {
    //   router.push('/slideshow')
    // } else {
    //   router.push('/supplierlist')
    // }
    router.push('/home')
    // router.options.routes.concat(staticRoutesArr);
  },
  CHANGE_MENU_ALL_ORIGINAL(state, payload) {
    state.menuAllOriginal = payload
  },
  CHANGE_MENU_LIST(state, payload) {
    state.menuTreeList = payload
  }
}

// 过滤器
const filterMenu = menuAllOriginal => {
  if (!menuAllOriginal.length) return
  const myMenuType = ['MENU'] // 过滤的值
  return menuAllOriginal
    .filter(item => {
      return myMenuType.indexOf(item.types) > -1
    })
    .map(item => {
      item = Object.assign({}, item)
      if (item.children) {
        item.children = filterMenu(item.children, myMenuType)
      }
      return item
    })
}

const actions = {
  setMenuList({ commit, state }, type) {
    return new Promise(resolve => {
      let sessionMenuData = localStorage.getItem('MENU_ALL_ORIGINAL')
        ? JSON.parse(localStorage.getItem('MENU_ALL_ORIGINAL'))
        : []
      let sessionMenuTreeList = localStorage.getItem('MENU_TREE')
        ? JSON.parse(localStorage.getItem('MENU_TREE'))
        : []
      if (
        (sessionMenuData?.length > 0 || sessionMenuTreeList?.length > 0) &&
        !type
      ) {
        state.menuAllOriginal = sessionMenuData // 后端tree源数据
        state.menuTreeList = sessionMenuTreeList // 过滤后 + 静态路由  tree渲染的data
        // 真正的路由
        let restData = resetLayoutList(sessionMenuData)
        let routeTree = parsingTree(restData, [])
        commit('SET_ROUTER_MENULIST', routeTree)
        resolve(routeTree)
        return
      }
      getUserMenu().then(response => {
        const { code, result } = response
        if (code == 200) {
          result.push[{ path: '*', redirect: '/404', hidden: true }]
          state.menuAllOriginal = result
          localStorage.setItem('MENU_ALL_ORIGINAL', JSON.stringify(result))
          const myMenuList = result?.length ? filterMenu(result) : []
          // 解析剔除layout处理静态路由
          let restStaticData = resetLayoutList(staticRoutesArr)
          // 静态路由加进过滤后result里 tree渲染myMenuAddStatic
          const myMenuAddStatic = [...restStaticData].concat(myMenuList)
          // 没有过滤的真路由 + 静态
          const reallyRouter = [...restStaticData].concat(result)
          state.menuTreeList = myMenuAddStatic
          localStorage.setItem('MENU_TREE', JSON.stringify(myMenuAddStatic))
          // 真正的路由
          let restData = resetLayoutList(reallyRouter)
          let routeTree = parsingTree(restData, [])
          commit('SET_ROUTER_MENULIST', routeTree)
          resolve(routeTree)
        }
      })
    })
  },
  changeMenuAllOriginal({ commit }, log) {
    commit('CHANGE_MENU_ALL_ORIGINAL', log)
  },
  changeMenuList({ commit }, log) {
    commit('CHANGE_MENU_LIST', log)
  }
}
// 深度遍历路由
const deepRouterList = []
function deepRouter(list) {
  // eslint-disable-next-line no-extra-semi
  ;[list].forEach(item => {
    if (item.component == 'Layout') {
      if (item.children?.length > 0) {
        item.children.forEach(item2 => {
          deepRouter(item2)
        })
      }
    } else {
      deepRouterList.push(item)
    }
  })
  return deepRouterList
}
// 处理无效菜单与路由的匹配
function resetLayoutList(list) {
  let newArr = []
  if (list?.length > 0) {
    list.forEach(item => {
      if (item.component == 'Layout') {
        if (item.children?.length > 0) {
          item.children.forEach(child => {
            if (child.component == 'Layout') {
              if (child.children?.length > 0) {
                const val = deepRouter(child)
                newArr.push(...val)
              }
            } else {
              newArr.push(child)
            }
          })
        }
      } else {
        newArr.push(item)
      }
    })
  }
  return newArr
}

// 解析后端返回来的菜单树
function parsingTree(arr, list) {
  // let newArr = arr.concat(...staticRoutesArr);
  arr.forEach((item, index) => {
    list.push({
      path: item.path || '',
      name: item.name,
      types: item.types,
      // eslint-disable-next-line no-extra-boolean-cast
      component: !!item.component
        ? resolve => require([`src/${item.component}.vue`], resolve)
        : null,
      meta: {
        title: item.meta?.title || '',
        icon: item.meta?.icon || '',
        button: item.meta?.button || ''
      },
      id: item.id,
      children: []
    })

    if (item.children && item.children.length > 0) {
      const childArr = parsingTree(item.children, [])
      list[index].children = childArr
    }
  })
  return list
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
