import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
// import httpBaseConfig from 'util/js/httpConfig'
import constant from 'constant'
import { getToken } from 'util/js/headerToken'
import store from 'src/store'
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  402: '参数异常',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  408: '请求失败了',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}
// 全局设置 axios 发送请求带上cookie
axios.defaults.withCredentials = true
axios.withCredentials = true
// 拦截请求
axios.interceptors.request.use(
  config => {
    //token检查
    let token = getToken()
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!token) {
      config.headers['token'] = token
    }
    config.headers['Authorization'] = localStorage.getItem('Authorization')
    // config.headers['Z-Auth-Position'] = 'gateway'
    // 前缀+url
    config.url = constant.GLOBAL_CONFIG.BASE_API_URL + config.url
    return config
  },
  error => {
    console.dir(error)
  }
)

// 拦截响应
axios.interceptors.response.use(
  data => {
    if (data.data.code === 200) {
      return data
    } else if (!data.data.code) {
      return data
    } else {
      Message.closeAll()
      Message({
        message:
          data.data.message || data.data.desc || '错误提示：请重新刷新页面！',
        type: 'error',
        duration: 1500
      })
      localStorage.setItem('RequestErrmessage', data.data.desc)
    }
  },
  error => {
    const errortext = codeMessage[error.response.status]
    if (error.response.status == 401 || error.response.status == 403) {
      // "错误提示：登录超时，请重新登录!";
      exitToLogin()
    }

    if (error.response.data.desc || error.response.data.message) {
      Message.closeAll()
      Message.error(error.response.data.desc || error.response.data.message)
    } else {
      Message.closeAll()
      Message.error(errortext)
    }
    // if (error.response && error.response.status == 401) {
    //   Message({ message: error.response.data.desc, type: "error", duration: 2000 });
    //   exitToLogin();
    // }
  }
)

axios.prototype.baseURL = constant.GLOBAL_CONFIG.BASE_API_URL

// 根据环境变量配置接口地址
// const getFullUrl = url => {
//   if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
//     return url
//   }
//   let newUrl
//   // 配置url以 / 结尾
//   if (httpBaseConfig.baseUrl.endsWith('/')) {
//     newUrl = httpBaseConfig.baseUrl + url.substring(1, url.length)
//   } else {
//     newUrl = httpBaseConfig.baseUrl + url
//   }

//   return newUrl
// }

function exitToLogin() {
  localStorage.clear()
  store.commit('user/SET_USER_LOGOUT')
  location.reload()
}
