/* 在请求token中添加token*/
// import constant from "constant";
// import md5 from 'js-md5';

/* 处理签名*/

// JWT签名（后期等待后端改造好鉴权同步实现）
// export function signActionTime(type, params) {
//   const { USER_TOKEN, USER_KEY } = constant.user;
//   let localUserInfo = localStorage.getItem(USER_KEY);
//   let token = localUserInfo && localUserInfo[USER_TOKEN] ? localUserInfo[USER_TOKEN] : '';
//   if (!token || token == "" || `${token}` == "null" || !localUserInfo) { return }
//   let header = {};
//   let secretKey = localStorage.getItem(USER_KEY).secretKey;
//   let requestType = type.toUpperCase();
//   let d = '';
//   let paramsStr = '';
//   if (requestType == 'POSTJSON') {
//     d = params ? JSON.stringify(params) : '';
//   } else {
//     paramsStr = params ? pars(objKeySort(params)) : '';
//   }
//   let time = new Date().getTime().toString();
//   let bodyMd5 = md5(d).toString();

//   let sign = md5(token + secretKey + time + paramsStr + bodyMd5).toString();
//   switch (requestType) {
//     case "GET":
//       header = {
//         "Accept": "application/json",
//         token,
//         time,
//         r: sign
//       };
//       break;
//     case "POSTFORM":
//       header = {
//         Accept: "application/json",
//         "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
//         token,
//         time,
//         r: sign
//       };
//       break;
//     case "POSTJSON":
//       header = {
//         "Content-Type": "application/json; charset=utf-8",
//         token,
//         time,
//         r: sign
//       }
//       break;
//   }
//   return header;
// }

// sesstion 简单签名
export function signActionTime(type) {
  let requestType = type.toUpperCase(),
    header
  switch (requestType) {
    case 'PUT':
      header = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      }
      break
    case 'GET':
      header = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      }
      break
    case 'POSTFORM':
      header = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
      break
    case 'POST':
      header = {
        'Content-Type': 'application/json; charset=utf-8'
      }
      break
    case 'POSTIMG':
      header = {}
      break
    case 'GETFILESTREAM':
      header = {}
      break
    case 'POSTFILEUPLOAD':
      header = {
        'Content-type': 'multipart/form-data'
      }
  }
  return header
}

/* 对象转拼接串的方法*/
export function pars(param, key, encode) {
  if (param == null) param = ''
  var arr = []
  var t = typeof param
  if (t == 'string' || t == 'number' || t == 'boolean') {
    arr.push(key + '=' + param)
  } else {
    for (var i in param) {
      var k =
        key == null
          ? i
          : key + (param instanceof Array ? '[' + i + ']' : '.' + i)
      arr.push(pars(param[i], k, encode))
    }
  }
  return arr.join('&')
}

// 对象通过Key排序的函数
export function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  let newkey = Object.keys(arys).sort()
  let newObj = {} //创建一个新的对象，用于存放排好序的键值对
  for (let i = 0; i < newkey.length; i++) {
    //遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]]
    //向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj //返回排好序的新对象
}

// setIsLogin
export function setIsLogin(loginState) {
  localStorage.setItem('isLogin', loginState)
}

// 获取IsLogin
export function getIsLogin() {
  return JSON.parse(localStorage.getItem('isLogin')) ? true : false
}

// 登出
export function setIsLogout() {
  localStorage.setItem('isLogin', false)
}

// 获取token
export function getToken() {
  return JSON.parse(localStorage.getItem('adminToken')) || ''
}

// 重置token
export function setToken(token) {
  localStorage.setItem('adminToken', JSON.stringify(token))
}

// 移除token
export function removeToken() {
  localStorage.removeItem('adminToken')
}

// 移除userInfo
export function removeUserInfo() {
  localStorage.removeItem('USER_INFO')
}
