<template>
  <div class="home">
    <el-container>
      <el-header ref="navMenu" style="display: flex; height: 61px">
        <!-- logo -->
        <div class="logoImg" ref="logoImg">
          <img src="@/assets/home_images/logostext.png" alt="" />
        </div>
        <div class="header">
          <div class="header-title">
            <span>{{ activeTitle || $route.name }}</span>
          </div>
          <el-dropdown>
            <span class="el-dropdown-link loginOut">
              <i class="el-icon-user" style="font-size: 18px"></i>
              {{ userName || name || '用户' }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native.prevent="loginOut">
                退出
              </el-dropdown-item>
              <el-dropdown-item @click.native.prevent="updatePass">
                修改密码
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <!-- 侧滑菜单 -->
        <el-aside
          :style="{ height: sideHeight }"
          :width="sideWidth"
          class="text-center"
        >
          <!-- :default-expanded-keys="expandedArr"
            :default-checked-keys="checkedArr" -->
          <el-tree
            ref="tree"
            class="menuTree"
            :data="menuTreeList"
            :props="defaultProps"
            node-key="id"
            @node-click="handleNodeClick"
            :current-node-key="currentLivingId"
            :default-expanded-keys="defaultExpandedKeys"
            :default-checked-keys="defaultCheckedKeys"
            :accordion="true"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <span v-if="data.icon" :class="[data.icon, 'mr10']"></span>
              <span>{{ data.name }}</span>
            </span>
          </el-tree>
        </el-aside>
        <el-main :style="{ height: mainHeight }" style="background: #ebedef">
          <router-view />
        </el-main>
      </el-container>
    </el-container>

    <!-- 修改密码弹窗 -->
    <el-dialog
      :visible.sync="uploadPasswordDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title" class="text-left">修改密码</div>
      <div>
        <el-form
          ref="modifyPwdFormData"
          :model="modifyPwdFormData"
          :rules="rules"
          label-width="0"
          label-position="left"
        >
          <el-form-item label="" prop="oldPwd">
            <el-input
              v-model="modifyPwdFormData.oldPwd"
              placeholder="输入原密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="newPwd">
            <el-input
              type="password"
              autocomplete="new-password"
              v-model="modifyPwdFormData.newPwd"
              placeholder="输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="rePwd">
            <el-input
              type="password"
              v-model="modifyPwdFormData.rePwd"
              placeholder="输入新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="handleCloseDialog('modifyPwdFormData')">
          取消
        </el-button>
        <el-button type="primary" @click="updatePwd('modifyPwdFormData')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { putEditPwdApi } from 'api/user'
export default {
  name: 'Layout',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.modifyPwdFormData.rePwd !== '') {
          this.$refs.modifyPwdFormData.validateField('rePwd')
        }
        callback()
      }
    }
    const checkpassword = (rule, value, callback) => {
      if (value === '') {
        callback('密码不能为空')
      } else if (value !== this.modifyPwdFormData.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userName: '',
      sideHeight: '',
      mainHeight: '',
      leftNav: [],
      rules: {
        oldPwd: [
          { required: true, message: '请输入旧密码' },
          { validator: validatePass, trigger: 'blur' }
        ],
        newPwd: [
          { required: true, message: '请输入新密码' },
          { validator: validatePass, trigger: 'blur' }
        ],
        rePwd: [
          { required: true, message: '请输入新密码' },
          { validator: checkpassword, trigger: 'blur' }
        ]
      },
      dialogVisible: false, //修改密码弹窗
      modifyPwdFormData: {
        oldPwd: '',
        newPwd: '',
        rePwd: ''
      },
      crumblist: [],
      breadList: [],
      topNavActive: '0',
      leftNavActive: '',
      allRoute: [],
      sideWidth: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      loading: true,
      currentLivingId: '', // 初始进来默认选中第一个
      expandedArr: [], // 展开数组
      checkedArr: [], // 选择数组
      defaultExpandedKeys: [], // 默认展开
      defaultCheckedKeys: [], // 默认选中
      activeTitle: ''
    }
  },
  computed: {
    ...mapState('user', ['isLogin', 'name', 'uploadPasswordDialog']),
    ...mapState('menu', ['menuTreeList', 'menuAllOriginal']) //左侧菜单
  },
  watch: {
    $route: {
      handler(to, from) {
        // 浏览器点击返回 菜单改变
        this.menuTreeList.filter(item => {
          if (item.path === to.path) {
            // sessionStorage.setItem("treeExpanded", item.id);
            sessionStorage.removeItem('treeChecked')
            this.$nextTick(function () {
              this.expandedArr.push(item.id)
              this.$refs.tree.setCurrentKey(item.id)
            })
            return
          }
          if (item.children?.length) {
            item.children?.forEach(cItem => {
              if (cItem.path === to.path) {
                // sessionStorage.setItem("treeExpanded", cItem.id);
                sessionStorage.removeItem('treeChecked')
                this.$nextTick(function () {
                  this.expandedArr.push(cItem.id)
                  this.$refs.tree.setCurrentKey(cItem.id)
                })
                return
              }
            })
          }
        })

        // 判断是否是第一次进入或者是刷新 如果刷新则session有存
        if (!from) {
          this.crumblist = sessionStorage.getItem('crumblist')
            ? JSON.parse(sessionStorage.getItem('crumblist'))
            : []
        } else {
          // this.getBreadcrumb(to, from);
        }
        this.getBreadList(to)
      },
      // 深度观察监听
      deep: true,
      immediate: true
    },
    menuTreeList() {
      this.isClickTree()
    }
  },
  created() {
    this.isClickTree()
    this.$nextTick(() => {
      setTimeout(() => {
        this.userName = JSON.parse(localStorage.getItem('USER_ALL_INFO'))
          ? JSON.parse(localStorage.getItem('USER_ALL_INFO')).name
          : ''
      }, 100)
    })
  },
  mounted() {
    // 获取浏览器可视区域高度
    let headerHeight = parseInt(this.$refs.navMenu.$el.style.height)
    this.sideWidth = parseInt(this.$refs.logoImg.clientWidth) + 'px'
    this.sideHeight =
      document.documentElement.clientHeight - headerHeight + 'px'
    this.mainHeight =
      document.documentElement.clientHeight - headerHeight + 'px'
    window.onresize = () => {
      let headerHeight
      if (this.$refs.navMenu) {
        headerHeight = parseInt(this.$refs.navMenu.$el.style.height)
        this.sideWidth = parseInt(this.$refs.logoImg.clientWidth) + 'px'
      }
      this.sideHeight =
        document.documentElement.clientHeight - headerHeight + 'px'
      this.mainHeight =
        document.documentElement.clientHeight - headerHeight + 'px'
    }
    this.refreshBorwser(this.menuTreeList)
  },
  methods: {
    // 浏览器刷新后默认选中
    refreshBorwser(data) {
      data.map(item => {
        if (`#${item.path}` === location.hash) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([])
            this.defaultExpandedKeys = [item.id]
            this.defaultCheckedKeys = [item.id]
            this.$refs.tree.setChecked([item.id], true, false)
            this.activeTitle = item.name
          })
        }
        if (item.children && item.children.length) {
          this.refreshBorwser(item.children)
        }
      })
    },
    // 判断缓存有没有点击tree
    isClickTree() {
      // let sessionExpanded = sessionStorage.getItem("treeExpanded");
      // let sessionChecked = sessionStorage.getItem("treeChecked");
      // if (sessionExpanded) {
      //   this.$nextTick(function () {
      //     this.expandedArr = [];
      //     this.expandedArr.push(sessionExpanded);
      //     this.$refs.tree.setCurrentKey(this.expandedArr[0]);
      //   });
      // }
      // if (sessionExpanded && sessionChecked) {
      //   this.checkedArr.push(sessionChecked);
      //   this.$nextTick(function () {
      //     this.checkedArr = [];
      //     this.checkedArr.push(sessionChecked);
      //     this.$refs.tree.setCurrentKey(this.checkedArr[0]);
      //   });
      // }
      // if (!sessionExpanded && !sessionChecked) {
      //   this.currentLivingId = this.menuTreeList[0]?.id;
      //   this.$nextTick(function () {
      //     this.$refs.tree.setCurrentKey(this.currentLivingId);
      //   });
      // }
    },
    handleNodeClick(data) {
      this.activeTitle = data.name
      this.$nextTick(() => {
        this.defaultExpandedKeys = []
        this.defaultCheckedKeys = []
        this.$refs.tree.setCheckedKeys([])
      })
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!data.path) {
        this.$router.push({
          path: data.path
        })
      }
    },
    choseTopNav(item) {
      this.crumblist = []
      this.leftNav = item.authList
      this.topNavActive = item.id
      sessionStorage.setItem('topNavActive', item.id)
    },
    choseLeftNav(item) {
      this.crumblist = []
      this.leftNavActive = item.id
      sessionStorage.setItem('leftNavActive', item.id)
      this.$router.push(item.path)
    },
    operateNavData(arr) {
      for (let key in arr) {
        if (arr[key].authList && arr[key].authList.length > 0) {
          this.operateNavData(arr[key].authList)
        } else {
          this.allRoute.push({
            id: arr[key].id,
            name: arr[key].name,
            path: arr[key].path
          })
        }
      }
    },
    getBreadList(val) {
      // 过滤路由matched对象
      let that = this
      that.forMenuTreeList(that.menuTreeList, val, res => {
        let arr = that.parentTree(that.menuTreeList, res.id)
        that.breadList = arr.reverse()
      })
    },
    forMenuTreeList(chli, val, callback) {
      chli.map(item => {
        if (item.path === val.path) {
          callback(item)
        }
        if (item.children) {
          this.forMenuTreeList(item.children, val, callback)
        }
      })
    },
    parentTree(arr, id) {
      // console.log(id)
      //arr 所有的树数据 id 某个子节点的id
      var temp = []
      var callback = function (nowArr, id) {
        //先定义个函数寻找子节点位置 找到后 再找改节点父元素位置 以此类推
        for (var i = 0; i < nowArr.length; i++) {
          var item = nowArr[i]
          if (item.id === id) {
            temp.push(item)
            callback(arr, item.pid) //pid 父级ID
            break
          } else {
            if (item.children) {
              callback(item.children, id) //menus 子节点字段名称
            }
          }
        }
      }
      callback(arr, id)
      return temp //最后返回
    },
    getBreadcrumb(toObj = {}, fromObj = {}) {
      // 判断是否是当前页
      let isCurrentPath = toObj.fullPath === fromObj.fullPath
      if (isCurrentPath) {
        this.crumblist = JSON.parse(sessionStorage.getItem('crumblist'))
        return
      }
      //判断是否是导航路径
      let isNavPath = this.allRoute.some(item => {
        return item.path == toObj.path
      })
      if (!isNavPath) {
        // 判断是否是面包屑菜单
        let iscrumbPath = this.crumblist.filter((item, index) => {
          if (item.path == toObj.path) {
            return index
          }
        })
        if (iscrumbPath.length <= 0) {
          let title = toObj.meta.title,
            path = toObj.path,
            query = toObj.query
          this.crumblist = this.crumblist.concat([{ title, path, query }])
        } else {
          this.crumblist = this.crumblist.slice(0, iscrumbPath + 1)
        }
        if (this.crumblist.length == 1) {
          this.crumblist.unshift({
            title: fromObj.meta.title,
            path: fromObj.path,
            query: fromObj.query
          })
        }
        sessionStorage.setItem('crumblist', JSON.stringify(this.crumblist))
      } else {
        this.crumblist = []
        sessionStorage.removeItem('crumblist')
      }
    },
    // 登出
    loginOut() {
      this.$store.dispatch('user/logout')
    },
    // 修改密码
    updatePass() {
      // this.dialogVisible = true
      // this.$store.commit('user/SET_UPDPWDDIALOG', true)
      // this.$router.push('/forget')
      this.$router.push('/edit')
    },
    handleCloseDialog(item) {
      this.$refs[item].resetFields()
      this.$store.commit('user/SET_UPDPWDDIALOG', false)
    },
    updatePwd(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          putEditPwdApi({ ...this.modifyPwdFormData })
            .then(result => {
              if (result.code == 200) {
                this.$message({ message: '修改成功！', type: 'success' })
                setTimeout(() => {
                  this.loginOut()
                }, 500)
              } else {
                this.$message({ message: result.desc, type: 'error' })
              }
            })
            .catch(() => {})
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
$bgColor: #434a50;
// $activeBgColor: #484f56;
$activeBgColor: #041527;
$headerColor: #f3f3f4;
.menu-nav-item {
  font-size: 14px;
}
.logoImg {
  // position: relative;
  width: 200px;
  // padding: 0 20px;
  line-height: 60px;
  // background: $activeBgColor;
  background: #0c274a;
  box-sizing: border-box;
  cursor: pointer;
  // border-bottom: 1px solid #393d46;
  // border-right: 1px solid $bgColor;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    // position: absolute;
    // left: 30%;
    // top: 20%;
    // transform: translate(-50%, -50%);
    display: inline-block;
    height: 36px;
  }
}
.menuTree {
  /deep/ .el-tree-node > .el-tree-node__children {
    padding-left: 10px;
    background-color: $activeBgColor;
  }
  /deep/ .el-tree-node {
    background-color: $activeBgColor;
    padding-bottom: 10px;
    span {
      font-size: 16px;
    }
  }
}

/deep/.el-menu.el-menu--horizontal {
  border: none !important;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
}
.el-header {
  padding: 0;
  margin-bottom: 0;
}
.contentMsg {
  overflow-y: scroll;
}
.loginOut {
  display: inline-block;
  height: 60px;
  line-height: 60px;
  margin: 0px;
  // color: #fff;
  color: #000;
  padding: 0 20px;
  cursor: pointer;
}
.header {
  // background: $activeBgColor;
  background: #fff;
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  .header-title {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    span {
      font-size: 18px;
      font-weight: bold;
      // color: #fff;
      display: flex;
      align-items: center;
    }
  }
}
.el-aside .el-menu-item.is-active {
  color: $primary;
  // background-color: $bgColor;
}
.el-menu-item {
  font-size: 16px !important;
}
/deep/ .el-submenu__title {
  font-size: 16px;
}
.el-main {
  padding: 0px;
  .el-card__header {
    border-bottom: 1px dashed #e5e5e5 !important;
  }
}
.el-aside {
  background-color: $activeBgColor;
  padding-top: 10px;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  /deep/ .el-tree-node__expand-icon.el-icon-caret-right {
    &:before {
      content: '\e6df';
    }
  }
  /deep/ .el-tree-node__expand-icon.expanded {
    transform: rotate(180deg);
  }
  /deep/ .el-tree-node__content {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    color: #aaabae;
    background-color: $activeBgColor;
    padding: 0 20px;
    box-sizing: border-box;
    list-style: none;
    cursor: pointer;
    position: relative;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
    white-space: nowrap;
    display: flex;
    flex-direction: row-reverse;
    & > span:last-child {
      flex: 1;
      text-align: left;
      padding-left: 20px;
    }
    &:active,
    &:hover {
      color: #fff;
      background-color: $activeBgColor;
    }
  }
  /deep/.el-tree-node:focus > .el-tree-node__content {
    // color: #fff;
    // background-color: #f18a2c;
    color: $primary;
    background-color: $activeBgColor;
  }
  /deep/.is-current > .el-tree-node__content,
  /deep/.is-checked > .el-tree-node__content {
    // color: #fff;
    // background-color: #f18a2c;
    color: $primary;
    background-color: $activeBgColor;
  }
}
</style>
