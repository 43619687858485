<template>
  <!-- ref 也可以在标签上使用 -->
  <div class="chart-box" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      chart: null,
    };
  },
  beforeDestroy() {
    this.chart && this.chart.dispose();
  },
  methods: {
    init() {
      console.log(12378917923791);
      // this.refs.chart === document.querySelector('.chart-box')
      this.chart = echarts.init(this.$refs.chart);
      console.log(this.chart);
    },
    draw(config) {
      if (!this.chart) this.init();
      this.chart.setOption(config);
    },
    resize() {
      if (!this.chart) this.init();
      this.chart.resize();
    }
  },
  mounted() {
    // const chart = echarts.init(this.$refs.chart);
    // chart.setOption({
    //   title: {
    //     text: "ECharts 入门示例",
    //   },
    // });

    // this.init();
  },
};
</script>
<style lang="scss" scoped>
.chart-box{
  width: 100%;
  height: 100%;
}
</style>
