export default {
  // 全局公共方法
  timeChange: function (time) {
    const nowDate = new Date().getTime()
    const times = time && time.replace(/-/g, '/').replace(/T/g, ' ')
    const reDate = new Date(times).getTime()
    const date = reDate - nowDate
    const days = parseInt(date / (1000 * 60 * 60 * 24))
    let d = days < 10 && days > 0 ? '0' + days : days < 0 ? '0' : days
    // eslint-disable-next-line no-compare-neg-zero
    d === '00' || d == -0 ? (d = '0') : d
    const hours = parseInt((date % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let h = hours < 10 && hours > 0 ? '0' + hours : hours < 0 ? '0' : hours
    const minutes = parseInt((date % (1000 * 60 * 60)) / (1000 * 60))
    let m =
      minutes < 10 && minutes > 0 ? '0' + minutes : minutes < 0 ? '0' : minutes
    d = d || '00'
    h = h || '00'
    m = m || '00'
    return d + '天' + h + '时' + m + '分'
  },
  // 数字转为ABCD
  numToABC(num) {
    return String.fromCharCode(65 + num)
  },
  // 数字转为中文 100以内
  numToChinese(val) {
    let chin = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
    if (val <= 10) {
      return chin[val - 1]
    } else if (val <= 100) {
      if (val < 20) {
        return '十' + chin[(val % 10) - 1]
      } else if (val % 10 === 0) {
        if (val === 100) {
          return '一百'
        }
        return chin[Math.floor(val / 10) - 1] + '十'
      } else {
        return chin[Math.floor(val / 10) - 1] + '十' + chin[(val % 10) - 1]
      }
    }
  },
  // 截取时间
  timeToYyMmDd(time) {
    // console.log(time,'time')
    if (!time) return
    if (!time.includes('T')) return time
    return time.split('T')[0] + ' ' + time.split('T')[1].substring(0, 8)
  },
  // ------------------------ 导出 ------------------------
  // 获取时间
  getMyDate() {
    let oDate = new Date(),
      oYear = oDate.getFullYear(),
      oMonth = oDate.getMonth() + 1,
      oDay = oDate.getDate(),
      oHour = oDate.getHours(),
      oMin = oDate.getMinutes(),
      oSen = oDate.getSeconds(),
      oTime =
        oYear +
        '-' +
        this.getzf(oMonth) +
        '-' +
        this.getzf(oDay) +
        ' ' +
        this.getzf(oHour) +
        ':' +
        this.getzf(oMin) +
        ':' +
        this.getzf(oSen) //最后拼接时间
    return oTime
  },
  getzf(num) {
    if (parseInt(num) < 10) {
      num = '0' + num
    }
    return num
  },
  exportHandle(Api, sendData, title) {
    Api(sendData).then(data => {
      let blob = new Blob([data.data], { type: 'application/vnd.ms-excel' })
      console.log(blob, 'blob')
      // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
      let blobUrl = window.URL.createObjectURL(blob)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.download = this.getMyDate() + `${title}.xlsx`
      link.href = blobUrl
      link.click()
      link.remove()
      // 5.释放这个临时的对象url
      window.URL.revokeObjectURL(blobUrl)
    })
  },
  // ------------------------ 字典枚举 ------------------------
  // 报价状态
  quoteStateShow(state) {
    // supplier_quote_state_enum
    const sup = JSON.parse(
      localStorage.getItem('DICTIONARY')
    ).supplier_quote_state_enum
    // 10 20 30 已结束 40已中标 45未中标 50待定标 60进行中
    let val
    sup.forEach(item => {
      if (item.k == state) {
        val = item.v
      }
    })
    return val
  },
  // 询价单状态
  inquiryStateShow(state) {
    // purchaser_inquiry_state_enum
    const pur = JSON.parse(
      localStorage.getItem('DICTIONARY')
    ).purchaser_inquiry_state_enum
    // 10已结束 20已废标 30已取消 40已完成 45未中标 50待定标 60报价中
    let val
    pur.forEach(item => {
      if (item.k == state) {
        val = item.v
      }
    })
    return val
  },
  // 邀请列表状态
  invitationState(state) {
    if (state == 30) {
      return '已结束'
    } else if (state == 20) {
      return '已报价'
    } else if (state == 10) {
      return '未报价'
    }
  },
  // 广告位置2
  // advertLevel() {
  //   return dic.advert_level_location;
  // }

  // 员工管理列表的状态管理==设为管理员
  setAdminCode(code) {
    if (code !== 'ADMIN_SALESMAN' && code !== 'ADMIN_BUYER') {
      return true
    } else {
      return false
    }
  },
  // ------------------------ 通用字典枚举 ------------------------
  getEnumType(state, type) {
    // supplier_quote_state_enum
    const sup = JSON.parse(localStorage.getItem('DICTIONARY'))[type]
    // 10 20 30 已结束 40已中标 45未中标 50待定标 60进行中
    let val
    if (!state) {
      val = sup
    } else {
      sup.forEach(item => {
        if (item.k == state) {
          val = item.v
        }
      })
    }
    return val
  },
  clientSourceUrl(state) {
    const zcb_do = JSON.parse(
      localStorage.getItem('DICTIONARY')
    ).zcb_domain
    let val
    console.log(state,'state')
    zcb_do.forEach(item => {
      if (item.k == state) {
        val = item.v
      }
    })
    return val
  },
}
