import { getDictionaryAll } from 'src/api/user'
// import { Message } from 'element-ui'
// import store from 'src/store'

const state = {
  DICTIONARY: {}
}

const mutations = {
  SET_DICTIONARY: (state, data) => {
    state.DICTIONARY = data
    localStorage.setItem('DICTIONARY', JSON.stringify(data))
  }
}

const actions = {
  getDictionaryAll({ commit }) {
    return new Promise((resolve, reject) => {
      getDictionaryAll()
        .then(response => {
          const { code, result } = response
          if (code == 200) {
            commit('SET_DICTIONARY', result)
          }
          resolve(result)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
