export default {
  data() {
    return { userbtn: [] }
  },
  // created() {
  //   this.userbtn = this.$route?.meta.button
  //   if(!this.userbtn){
  //     this.userbtn = []
  //   }
  // }
  watch: {
    $route: {
      handler(to) {
        this.userbtn = this.$route?.meta.button
        if (!this.userbtn) {
          this.userbtn = []
        }
      },
      immediate: true,
      deep: true
    }
  }
}
