import moment from 'moment'

/*
 * 处理时间格式
 * value   时间戳(13)
 * type    格式转化类型
 *  */
export function formatTime(value, type = 'HH:mm') {
  let val = Number(value)
  return moment(val).format(type)
}
