export default {
  GLOBAL_CONFIG: {
    BASE_URL: window.location.origin,
    // BASE_API_URL: 'api/uccn',
    BASE_API_URL: 'api/zckj',
    MULTI_LANGUAGE: true,
    UPLOADIMG_Url: window.location.origin + '/api/zckj/common/upload',
    // UPLOADIMG_Url: "http://localhost:7080" + '/api/zckj/common/upload',
    UPLOADFILE_Url:
      window.location.origin + '/ospn/api/vendor/uploadExcelChapter',
    UPLOADFILES_Url:
      window.location.origin + '/ospn/api/vendor/evaluate/uploadExcelEvaluate'
  },
  signature: 'E6AgEAAkEAno2nAMc3b', // 登录时密码前拼接字符串,再md加密
  platform: {
    pc: 2 //端
  },
  user: {
    USER_KEY: 'user', //localStorage存储的key
    USER_TOKEN: 'adminToken', // token,
    USER_IS_LOGIN: 'isLogin', //保存登录状态
    USERNAME: 'username', //用户名
    USER_INFO: 'userInfo'
  }
}


// export default {
//   GLOBAL_CONFIG: {
//     BASE_URL: "http://localhost:7080",
//     // BASE_API_URL: 'api/uccn',
//     BASE_API_URL: 'api/zckj',
//     MULTI_LANGUAGE: true,
//     UPLOADIMG_Url: "http://localhost:7080" + '/api/zckj/common/upload',
//     UPLOADFILE_Url:
//       "http://localhost:7080" + '/ospn/api/vendor/uploadExcelChapter',
//     UPLOADFILES_Url:
//       "http://localhost:7080" + '/ospn/api/vendor/evaluate/uploadExcelEvaluate'
//   },
//   signature: 'E6AgEAAkEAno2nAMc3b', // 登录时密码前拼接字符串,再md加密
//   platform: {
//     pc: 2 //端
//   },
//   user: {
//     USER_KEY: 'user', //localStorage存储的key
//     USER_TOKEN: 'adminToken', // token,
//     USER_IS_LOGIN: 'isLogin', //保存登录状态
//     USERNAME: 'username', //用户名
//     USER_INFO: 'userInfo'
//   }
// }
