import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import debounce from './mixins/debounce'
import btnlist from './mixins/btnlist' //用户角色拥有的权限（控制button）
import 'util/js/mainConfig' // 导入全局配置
import 'util/css/theme/index.css'
import ElementUI from 'element-ui'
import 'util/css/element-variables.scss'
import utils from 'util/js/util.js'

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
//样式
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
//主题
import 'tinymce/themes/silver'
//插件
import 'tinymce/plugins/link' //链接插件
import 'tinymce/plugins/image' //图片插件
import 'tinymce/plugins/media' //媒体插件
import 'tinymce/plugins/table' //表格插件
import 'tinymce/plugins/lists' //列表插件
import 'tinymce/plugins/quickbars' //快速栏插件
import 'tinymce/plugins/fullscreen' //全屏插件
import 'tinymce/icons/default/icons' //5.3.x版本需要额外引进图标
//本地化
import 'util/langs/tinymce/zh_CN.js'
import * as moment from 'moment'

Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

Vue.prototype.$moment = moment
Vue.prototype.utils = utils
Vue.config.productionTip = false
Vue.prototype.$baseurl = process.env.BASE_URL
Vue.use(ElementUI)
Vue.mixin(debounce)
Vue.mixin(btnlist)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
