import apiServer from 'api/apiServer.js'

// login
export function getMenu(params = {}) {
  let url = `/auth/module/element/ui/all`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// get user's menu
export function getUserMenu(params = {}) {
  return apiServer
    .get('/auth/module/element/ui/current/user', params)
    .then(res => {
      return Promise.resolve(res)
    })
}
