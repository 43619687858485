import apiServer from 'api/apiServer.js'

// login
export function postLoginApi(params = {}) {
  let url = `/auth/platform/login` //post methods
  // let url = `/logo/login`;get methods
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getUserInfoApi(params = {}) {
  let url = `/auth/account/current/user/info`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function getLogoutApi(params = {}) {
  let url = `/auth/logout` //get methods
  // let url = `/logo/signOut`;del methods
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 生产随机码
export function getRandomCode(params) {
  let url = `/verification/code/randomCode`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 修改密码
export function putEditPwdApi(params) {
  let url = `/auth/account/update/pwd`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}
// 字典
export function getDictionaryAll(params) {
  let url = `/white/basic/dictionary/all`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function userCodeApi(params) {
  let url = `/white/sms/forget/password/platform/code`
  return apiServer.get(url, params).then(res => {
    return Promise.resolve(res)
  })
}

export function forgetPasswordApi(params) {
  let url = `/white/auth/user/platform/forgetPassword`
  return apiServer.post(url, params).then(res => {
    return Promise.resolve(res)
  })
}

// 修改用户头像
export function editFaceApi(params) {
  let url = `/auth/account/edit/face`
  return apiServer.put(url, params).then(res => {
    return Promise.resolve(res)
  })
}