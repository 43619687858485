import Vue from 'vue'
import * as filters from 'src/filters' // 导入全局过滤器
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'util/css/index.scss' // css 全局样式
import 'util/js/requestConfig' // request control
import 'util/js/permission' // permission control
import * as echarts from "echarts";
Vue.prototype.$echarts = function (el) {
  return echarts.init(el, null, {renderer: 'svg'})
}
import ChartBox from '@/components/ChartBox/ChartBox.vue'
Object.keys(filters).forEach(key => Vue.filter(key, filters[key])) // 注册过滤器
Vue.component("ChartBox", ChartBox);

Vue.use(ElementUI)
