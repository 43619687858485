var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            {
              ref: "navMenu",
              staticStyle: { display: "flex", height: "61px" },
            },
            [
              _c("div", { ref: "logoImg", staticClass: "logoImg" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/home_images/logostext.png"),
                    alt: "",
                  },
                }),
              ]),
              _c(
                "div",
                { staticClass: "header" },
                [
                  _c("div", { staticClass: "header-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.activeTitle || _vm.$route.name)),
                    ]),
                  ]),
                  _c(
                    "el-dropdown",
                    [
                      _c("span", { staticClass: "el-dropdown-link loginOut" }, [
                        _c("i", {
                          staticClass: "el-icon-user",
                          staticStyle: { "font-size": "18px" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.userName || _vm.name || "用户") + " "
                        ),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.loginOut.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" 退出 ")]
                          ),
                          _c(
                            "el-dropdown-item",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.updatePass.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" 修改密码 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                {
                  staticClass: "text-center",
                  style: { height: _vm.sideHeight },
                  attrs: { width: _vm.sideWidth },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "menuTree",
                    attrs: {
                      data: _vm.menuTreeList,
                      props: _vm.defaultProps,
                      "node-key": "id",
                      "current-node-key": _vm.currentLivingId,
                      "default-expanded-keys": _vm.defaultExpandedKeys,
                      "default-checked-keys": _vm.defaultCheckedKeys,
                      accordion: true,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              data.icon
                                ? _c("span", { class: [data.icon, "mr10"] })
                                : _vm._e(),
                              _c("span", [_vm._v(_vm._s(data.name))]),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-main",
                {
                  staticStyle: { background: "#ebedef" },
                  style: { height: _vm.mainHeight },
                },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.uploadPasswordDialog,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadPasswordDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("修改密码")]
          ),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "modifyPwdFormData",
                  attrs: {
                    model: _vm.modifyPwdFormData,
                    rules: _vm.rules,
                    "label-width": "0",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "oldPwd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入原密码" },
                        model: {
                          value: _vm.modifyPwdFormData.oldPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.modifyPwdFormData, "oldPwd", $$v)
                          },
                          expression: "modifyPwdFormData.oldPwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "newPwd" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          autocomplete: "new-password",
                          placeholder: "输入新密码",
                        },
                        model: {
                          value: _vm.modifyPwdFormData.newPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.modifyPwdFormData, "newPwd", $$v)
                          },
                          expression: "modifyPwdFormData.newPwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "rePwd" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", placeholder: "输入新密码" },
                        model: {
                          value: _vm.modifyPwdFormData.rePwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.modifyPwdFormData, "rePwd", $$v)
                          },
                          expression: "modifyPwdFormData.rePwd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseDialog("modifyPwdFormData")
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updatePwd("modifyPwdFormData")
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }