import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from 'views/layout'

Vue.use(VueRouter)
// all roles can be accessed
export const constantRoutes = [
  {

    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ 'views/login/index'),
    hidden: true
  },
  {
    path: '/forget',
    name: '忘记密码',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/login/forget'),
  },
  {
    path: '/edit',
    name: '修改密码',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/login/edit'),
  },
  {
    path: '/transition',
    name: '转换',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/client/transit'),
  },
  {
    path: '/clientRegisterPC',
    name: '客户登记PC',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/client/indexPC'),
  },
  {
    path: '/clientRegisterMobile',
    name: '客户登记Mobile',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/client/indexMobile'),
  },
  {
    path: '/submitOK',
    name: '提交成功',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/client/submitOK'),
  },
  {
    path: '/unsubscribe',
    name: '退订成功',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/unsubscribe/index'),
  },
  {
    path: '/data',
    name: '大数据可视化',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/data'),
    meta:{
      title:"动态宝实时数据"
    }
  },
  {
    path: '/explain',
    name: '解释说明',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/explain/index.vue'),
    meta:{
      title:"解释说明"
    }
  },
  {
    path: '/untreated',
    name: '未处理列表',
    component: () =>
      import(/* webpackChunkName: "forget" */ 'views/printScreen/untreated.vue'),
    meta:{
      title:"未处理列表"
    }
  },
  {
    path: '/404',
    name: '404Page',
    component: () =>
      import(/* webpackChunkName: "404Page" */ 'views/error-page/404'),
    hidden: true
  },
  // {
  //   path: '/front',
  //   name: '首页',
  //   component: () =>
  //   import(/* webpackChunkName: "front" */ 'views/front/index'),
  // },
  {
    path: '/tryuse',
    name: '申请试用',
    component: () =>
      import(/* webpackChunkName: "tryuse" */ 'views/tryuse/index'),
  },
  // supplierlist
  // { path: '*', redirect: '/404', hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: '',
    children: [
      {
        path: '/home',
        name: '首页',
        icon: 'el-icon-s-home',
        id: 0,
        component: () => import(/* webpackChunkName: "home" */ 'views/home/index.vue'),
        meta: {
          button: ["HOME_LOOK", "HOME_EXPORT"]
        }
      },
      {
        path: '/articleDetail',
        name: '文章详情',
        icon: 'el-icon-s-home',
        id: 0,
        component: () => import(/* webpackChunkName: "home" */ 'views/home/articleDetail.vue')
      }

      // {//发布内容
      //   path: '/release',
      //   name: 'release',
      //   component: () =>
      //     import(/* webpackChunkName: "404Page" */ 'views/release/index'),
      //   hidden: true
      // },
      // {//发布内容 表单
      //   path: '/releaseForm',
      //   name: 'releaseForm',
      //   component: () =>
      //     import(/* webpackChunkName: "release" */ 'views/release/releaseForm'),
      //   hidden: true
      // },

    ]
  }
]

export const staticRoutesArr = [
  {
    path: '/',
    component: 'Layout',
    // redirect: '/home',
    name: '',
    children: [
      {
        path: '/home',
        name: '首页',
        icon: 'el-icon-s-home',
        id: 0,
        component: 'views/home',
        meta: {
          button: ["HOME_LOOK", "HOME_EXPORT"]
        }
      }
    ]
  }
]



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    base: process.env.BASE_URL,
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
